import _conversions from "./conversions";

var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};
var conversions = _conversions;

var convert = function () {
  return new Converter();
};

for (var func in conversions) {
  // export Raw versions
  convert[func + "Raw"] = function (func) {
    // accept array or plain args
    return function (arg) {
      if (typeof arg == "number") arg = Array.prototype.slice.call(arguments);
      return conversions[func](arg);
    };
  }(func);

  var pair = /(\w+)2(\w+)/.exec(func),
      from = pair[1],
      to = pair[2]; // export rgb2hsl and ["rgb"]["hsl"]

  convert[from] = convert[from] || {};

  convert[from][to] = convert[func] = function (func) {
    return function (arg) {
      if (typeof arg == "number") arg = Array.prototype.slice.call(arguments);
      var val = conversions[func](arg);
      if (typeof val == "string" || val === undefined) return val; // keyword

      for (var i = 0; i < val.length; i++) val[i] = Math.round(val[i]);

      return val;
    };
  }(func);
}
/* Converter does lazy conversion and caching */


var Converter = function () {
  (this || _global).convs = {};
};
/* Either get the values for a space or
  set the values for a space, depending on args */


Converter.prototype.routeSpace = function (space, args) {
  var values = args[0];

  if (values === undefined) {
    // color.rgb()
    return this.getValues(space);
  } // color.rgb(10, 10, 10)


  if (typeof values == "number") {
    values = Array.prototype.slice.call(args);
  }

  return this.setValues(space, values);
};
/* Set the values for a space, invalidating cache */


Converter.prototype.setValues = function (space, values) {
  (this || _global).space = space;
  (this || _global).convs = {};
  (this || _global).convs[space] = values;
  return this || _global;
};
/* Get the values for a space. If there's already
  a conversion for the space, fetch it, otherwise
  compute it */


Converter.prototype.getValues = function (space) {
  var vals = (this || _global).convs[space];

  if (!vals) {
    var fspace = (this || _global).space,
        from = (this || _global).convs[fspace];
    vals = convert[fspace][space](from);
    (this || _global).convs[space] = vals;
  }

  return vals;
};

["rgb", "hsl", "hsv", "cmyk", "keyword"].forEach(function (space) {
  Converter.prototype[space] = function (vals) {
    return this.routeSpace(space, arguments);
  };
});
exports = convert;
export default exports;